const searchOpen = document.getElementById('search-open');

if (searchOpen !== null) {
  searchOpen.addEventListener('click', (evnt) => {
    document.body.classList.add('search-open');
    const formbody = document.getElementById('search-form');
    if (formbody) {
      const searchInput = formbody.querySelector('input.text');
      searchInput.focus();
    }
  });
}

const searchClose = document.getElementById('search-close');
if (searchClose !== null) {
  searchClose.addEventListener('click', (evnt) => {
    document.body.classList.remove('search-open');
  });
}

window.addEventListener('click', (event) => {
  if (document.body.classList.contains('search-open')) {
    const eventTarget = event.target;
    const formbody = document.getElementById('search-form');
    if (formbody === eventTarget) {
      document.body.classList.remove('search-open');
    }
  }
});
